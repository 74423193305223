<template>
  <div class="body">
    <div class="wrap">
      <Banner />
      <div class="content">
        <div>
          <div>
            <el-menu
              :default-active="$route.path"
              class="left-nav"
              mode="horizontal"
              @select="handleSelect"
              background-color="#C10000"
              text-color="#fff"
              active-text-color="#AB4800"
              router
            >
              <el-menu-item index="/pc/home/meetinginfo">首页</el-menu-item>
              <el-menu-item index="/pc/home/invitationletter"
                >主席致辞</el-menu-item
              >
              <el-menu-item index="/pc/home/organization"
                >组织架构</el-menu-item
              >
              <el-menu-item index="/pc/home/experts">专家查询</el-menu-item>
              <el-menu-item index="/pc/home/basicinfo">会议信息</el-menu-item>
              <el-menu-item index="/pc/home/pcschedule">会议日程</el-menu-item>
              <el-menu-item index="/pc/home/cloudHall">线上展台</el-menu-item>
              <el-menu-item index="/pc/home/playback">会议回放</el-menu-item>
              <el-menu-item index="/pc/home/meetingLive">会议直播</el-menu-item>
              <el-menu-item index="/pc/home/photoLive">照片直播</el-menu-item>
              <!-- <el-menu-item index="/pc/home/meetingNotify"
                >参会通知</el-menu-item
              > -->
              <el-menu-item index="/pc/home/registrationGuide"
                >会议手册</el-menu-item
              >
              <el-menu-item index="/pc/home/creditInfo">学分注册</el-menu-item>
              <el-menu-item index="/pc/home/contactUs">联系我们</el-menu-item>
            </el-menu>
          </div>
          <div class="left">
            <div class="left-main">
              <router-view />
            </div>
          </div>
          <div class="right">
            <div class="right-item count-down">
              <div class="r-title">
                <span>距离会议开始还有：</span>
              </div>
              <div class="the-remaining-time">
                <div>
                  <div id="remaining-days" class="num">{{ countDown.day }}</div>
                  <span>天</span>
                </div>
                <div>
                  <div id="remaining-hours" class="num">
                    {{ countDown.hour }}
                  </div>
                  <span>时</span>
                </div>
                <div>
                  <div id="remaining-minutes" class="num">
                    {{ countDown.minute }}
                  </div>
                  <span>分</span>
                </div>
                <div>
                  <div id="remaining-seconds" class="num">
                    {{ countDown.second }}
                  </div>
                  <span>秒</span>
                </div>
              </div>
              <div class="two-entrances">
                <div v-if="false">
                  <el-button class="loginBtn" type="primary" size="medium "
                    >个人代表登录</el-button
                  >
                </div>
              </div>
            </div>
            <div class="right-item">
              <div class="r-header">
                <span>重要时间</span>
                <img src="@/assets/pc/icon_time.svg" alt="" />
              </div>
              <div class="important-time">
                <p>
                  <span
                    style="
                      color: #c10000;

                      font-size: 14px;
                    "
                    >会议日期</span
                  >
                </p>
                <p style="margin-bottom: 10px">
                  <span
                    style="
                      color: rgb(33, 37, 41);

                      font-size: 14px;
                    "
                    >2021年07月23-25日</span
                  >
                </p>
                <p>
                  <span
                    style="
                      color: #c10000;

                      font-size: 14px;
                    "
                    >开幕日期<br
                  /></span>
                </p>
                <p style="margin-bottom: 10px">
                  <span
                    style="
                      color: rgb(33, 37, 41);

                      font-size: 14px;
                    "
                    >2021年07月24日</span
                  >
                </p>
              </div>
            </div>
            <div class="right-item">
              <div class="r-header">
                <span>组织机构</span>
                <img src="@/assets/pc/icon_org.svg" alt="" />
              </div>
              <div class="important-time zbf1">
                <p><span class="zbf2">主办方</span></p>
                <p>
                  <span
                    style="
                      color: rgb(33, 37, 41);
                      font-size: 14px;
                      text-indent: 0em;
                    "
                    >中华医学会北京分会</span
                  >
                </p>
                <p>
                  <span
                    style="
                      box-sizing: border-box;
                      color: rgb(33, 37, 41);
                      font-size: 14px;
                    "
                    >北京医学会放射学分会</span
                  >
                </p>
                <p><span class="zbf2">协办方</span></p>
                <p>
                  <span
                    style="
                      color: rgb(33, 37, 41);
                      font-size: 14px;
                      text-indent: 0em;
                    "
                    >北京医师协会放射医师分会</span
                  >
                </p>
                <p>
                  <span
                    style="
                      color: rgb(33, 37, 41);
                      font-size: 14px;
                      text-indent: 0em;
                    "
                    >天津市医学会放射学分会</span
                  >
                </p>
                <p>
                  <span
                    style="
                      color: rgb(33, 37, 41);
                      font-size: 14px;
                      text-indent: 0em;
                    "
                    >河北省医学会放射学分会</span
                  >
                </p>
                <p><span class="zbf2">承办方</span></p>
                <p>
                  <span
                    style="
                      box-sizing: border-box;
                      color: rgb(33, 37, 41);
                      font-size: 14px;
                    "
                    >北京医院</span
                  >
                </p>
                <p>
                  <span
                    style="
                      color: rgb(33, 37, 41);
                      font-size: 14px;
                      text-indent: 0em;
                    "
                    >北京协和医院</span
                  >
                </p>
              </div>
            </div>
            <div class="right-item">
              <div class="r-header">
                <span>联系我们</span>
                <img src="@/assets/pc/icon_call.svg" alt="" />
              </div>
              <div class="important-time zbf1">
                <p><span class="zbf2">注册联系人</span></p>
                <p>
                  <span
                    style="
                      box-sizing: border-box;
                      color: rgb(33, 37, 41);
                      font-size: 14px;
                    "
                    >刘欣竹 010-58138086</span
                  >
                </p>
                <p>
                  <span
                    style="
                      box-sizing: border-box;
                      color: rgb(33, 37, 41);
                      font-size: 14px;
                    "
                    >19910216241</span
                  >
                </p>
                <p><span class="zbf2">企业联系人</span></p>
                <p>
                  <span
                    style="
                      box-sizing: border-box;
                      color: rgb(33, 37, 41);
                      font-size: 14px;
                    "
                    >黄小慧 15801500248</span
                  >
                </p>
                <p><span class="zbf2">北京医学会</span></p>
                <p>
                  <span
                    style="
                      box-sizing: border-box;
                      color: rgb(33, 37, 41);
                      font-size: 14px;
                    "
                    >于银林 010-65252365</span
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="txt">
          <p style="font-size: 18px">
            北京医学会放射学分会2021年学术大会
            北京医师协会放射医师分会2021年学术大会 京津冀医学影像高峰论坛
            版权所有
          </p>
          <p style="color: black; font-size: 13px">
            大会官方网站内容未经允许，禁止拷贝或转载本站内容，禁止建立类似会议注册页面，否则追究法律责任！
          </p>
          <p></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/banner";
import tool from "@/utils";

export default {
  name: "Home",
  data() {
    return {
      titleArray: [],
      activeIndex2: "meetinginfo",
      countDown: {
        day: "--",
        hour: "--",
        minute: "--",
        second: "--",
      },
    };
  },
  methods: {
    handleSelect(key) {
      console.log(key);
      if (key === "/pc/home/photoLive") {
        window.location.assign("https://live.photoplus.cn/live/55260405");
      } else if (key === "/pc/home/meetingLive") {
        window.location.assign(
          "https://wx.vzan.com/live/mk/aggspread/1560960427/5dc0fa44-d33c-11eb-be34-18ded7a37b78"
        );
      }
    },
    startCountDown() {
      const endTime = new Date("2021/07/23 08:00:00"); // "2021-07-23 08:00:00"
      let result = null;
      const timer = setInterval(() => {
        const startTime = new Date();
        result = tool.countDown(startTime, endTime);
        if (result) {
          this.countDown = result;
          // console.log(result);
        } else {
          clearInterval(timer);
        }
      }, 1000);
    },
  },
  created() {
    // 开始倒计时
    this.startCountDown();
  },
  components: {
    Banner,
  },
};
</script>

<style scoped>
.body {
  /* background-color: rgb(229, 241, 253); */
}
.wrap {
  min-width: 1240px;
  min-height: 100%;
  background-size: 100% auto;
  background-color: #e5e5e5;
}
.el-menu >>> .is-active {
  border-bottom: 1px solid #ffde9b;
  background: linear-gradient(#ffde9b, #db9306);
}

.content {
  padding-top: 20px;
}
h4 {
  margin: 0;
}
.content > div {
  width: 1240px;
  min-height: 600px;
  margin: 0 auto;
}
.content > div .left-nav {
  overflow: initial;
}
.content > div .left-nav {
  background-color: #0171f6;
  color: #fff;
  height: 60px;
  font-weight: bold;
  overflow: hidden;
  margin-bottom: 20px;
}
.content > div > .left {
  width: 890px;
  float: left;
  background-color: #fff;
  box-shadow: 0 3px 20px rgb(47 114 237 / 23%);
}
.content > div > .left .left-main {
  box-sizing: border-box;
  padding: 20px 25px 50px 25px;
}

.loginBtn {
  width: 100%;
  height: 40px;
  background: linear-gradient(180deg, #ffde9b 0%, #db9306 100%);
  color: #843700;
  border: none;
  outline: none;
  border-radius: 4px;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
  letter-spacing: 2px;
}
/* .content > div > .right .two-entrances button  img {
  margin-left: 50px;
  height: 40px;
  margin-right: 15px;
} */

.content > div > .right {
  width: 311px;
  float: right;
}
.content > div > .right .right-item {
  background-color: #fff;
  margin-bottom: 20px;
  width: 100%;
  /* border: 1px solid transparent; */
  border-radius: 0px;
  box-shadow: 0 3px 20px rgb(47 114 237 / 23%);
  box-sizing: border-box;
  font-size: 14px;
}
.count-down {
  padding: 15px;
}
.count-down .r-title {
  font-size: 12px;
  color: #444444;
  font-weight: 400;
}
.right-item > .r-header {
  background-color: #c10000;
  padding: 10px 15px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}
.right-item > .important-time {
  padding: 10px 15px;
}
.zbf2 {
  color: #c10000;
}

.content > div > .right .the-remaining-time {
  padding: 25px 0 25px 0;
  margin-bottom: 18px;
  /* border-bottom: 1px solid #e5f3fc; */
}
.clear:after,
.content > div:after,
.content > div .left-nav:after,
.content > div > .left .left-main .past-review:after,
.content > div > .right .two-entrances:after,
.content > div > .right .the-remaining-time:after {
  content: "";
  display: block;
  clear: both;
}
*,
::after,
::before {
  box-sizing: border-box;
}
.content > div > .right .the-remaining-time > div {
  width: 25%;
  text-align: center;
  float: left;
}
.content > div > .right .the-remaining-time > div .num {
  width: 50px;
  height: 50px;
  background-color: #f3ead8;
  color: #c10000;
  font-weight: bold;
  font-size: 22px;
  line-height: 2.3em;
  margin: 0 auto;
}
.content > div > .right .the-remaining-time > div span {
  font-size: 14px;
  color: #333333;
  margin-top: 5px;
  display: block;
}
.footer {
  width: 100%;
  position: relative;
  height: 300px;
}
.footer > .txt {
  width: 100%;
  position: absolute;
  text-align: center;
  bottom: 38px;
  line-height: 2.5em;
  text-align: center;
}
</style>